/* tema pai*/
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: var(--font-base);
  color: var(--color-base);
}
dl,
dt,
dd,
ol,
ul,
li {
  vertical-align: baseline;
  color: var(--color-base);
  font-family: var(--font-base);
  font-size: 1.6rem;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  background-color: var(--color-white);
  line-height: 1;
  font-size: 62.5%!important;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
strong,
b {
  font-weight: bold;
}
i,
em {
  font-style: italic;
}
a {
  text-decoration: none;
}
p a {
  font-weight: bold;
  text-decoration: underline;
}
p a:hover {
  text-decoration: underline;
}
img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
}
:focus {
  outline: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
p:not([class]) {
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: var(--font-base);
  color: var(--color-base);
  margin-bottom: 15px!important;
}
h1:not([class]),
h2:not([class]),
h3:not([class]),
h4:not([class]),
h5:not([class]),
h6:not([class]) {
  color: var(--color-primary);
  font-family: var(--font-stack);
  margin-bottom: 1rem;
  line-height: 1.5;
}
h1:not([class]) {
  font-size: 3.2rem;
}
h2:not([class]) {
  font-size: 2.4rem;
}
h3:not([class]) {
  font-size: 1.9rem;
}
h4:not([class]) {
  font-size: 1.5rem;
}
h5:not([class]) {
  font-size: 1.3rem;
}
h6:not([class]) {
  font-size: 1.1rem;
}
.wrap > p,
.wrap > h1,
.wrap > h2,
.wrap > h3,
.wrap > h4,
.wrap > h5,
.wrap > h6 {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
}
ul:not([class]):not([id]) li {
  line-height: 1.5;
  margin-bottom: 10px;
}
/*

// Paleta de cores

*/
:root {
  --color-white: #fff;
  --color-base: #444;
  --color-base-light1: #909090;
  --color-base-light2: #D0D0D0;
  --color-base-dark1: #515151;
  --color-base-dark2: #333333;
  --color-primary: #4E7AC7;
  --color-primary-light1: ;
  --color-primary-light2: ;
  --color-primary-dark1: ;
  --color-primary-dark2: ;
  --color-secondary: #C7A04E;
  --color-secondary-light1: ;
  --color-secondary-light2: ;
  --color-secondary-dark1: ;
  --color-secondary-dark2: ;
  --font-stack: "Roboto";
  --font-base: "Roboto";
}
/*

// Configurações gerais

*/
.bg-primary {
  background: var(--color-primary);
}
.bg-primary-wrap .wrap {
  background: var(--color-primary);
}
.bg-primary-light1 {
  background: var(--color-primary-light1);
}
.bg-primary-light1-wrap .wrap {
  background: var(--color-primary-light1);
}
.bg-primary-light2 {
  background: var(--color-primary-light2);
}
.bg-primary-light2-wrap .wrap {
  background: var(--color-primary-light2);
}
.bg-primary-dark1 {
  background: var(--color-primary-dark1);
}
.bg-primary-dark1-wrap .wrap {
  background: var(--color-primary-dark1);
}
.bg-primary-dark2 {
  background: var(--color-primary-dark2);
}
.bg-primary-dark2-wrap .wrap {
  background: var(--color-primary-dark2);
}
.bg-secondary {
  background: var(--color-secondary);
}
.bg-secondary-wrap .wrap {
  background: var(--color-secondary);
}
.bg-secondary-light1 {
  background: var(--color-secondary-light1);
}
.bg-secondary-light1-wrap .wrap {
  background: var(--color-secondary-light1);
}
.bg-secondary-light2 {
  background: var(--color-secondary-light2);
}
.bg-secondary-light2-wrap .wrap {
  background: var(--color-secondary-light2);
}
.bg-secondary-dark1 {
  background: var(--color-secondary-dark1);
}
.bg-secondary-dark1-wrap .wrap {
  background: var(--color-secondary-dark1);
}
.bg-secondary-dark2 {
  background: var(--color-secondary-dark2);
}
.bg-secondary-dark2-wrap .wrap {
  background: var(--color-secondary-dark2);
}
body {
  width: 100%;
  *zoom: 1;
}
body:before,
body:after {
  content: "";
  display: table;
}
body:after {
  clear: both;
}
img {
  max-width: 100%;
  height: auto;
}
.col {
  display: inline;
  float: left;
}
.wrap {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  *zoom: 1;
}
@media only screen and (max-width: 949px) {
  .wrap {
    padding: 0 15px;
  }
}
.wrap:before,
.wrap:after {
  content: "";
  display: table;
}
.wrap:after {
  clear: both;
}
.button {
  display: inline-block;
  min-width: 3rem;
  background: var(--color-primary);
  color: var(--color-white);
  padding: 0.5rem 1rem;
  font: 100 1.4rem var(--font-base);
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.1em;
  transition: 0.2s;
  border-radius: 0.3em;
  border: 1px solid var(--color-primary-dark1);
}
.button:hover,
.button:focus {
  background: var(--color-primary-dark1);
}
.button.large {
  padding: 0.8rem 2rem 1rem 2rem;
}
a.button:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
  margin-left: 5px;
  font-size: 1rem;
}
.title {
  font-family: var(--font-stack);
  font-size: 1.6rem;
  color: var(--color-primary);
}
/*
@color-menu-link
@bg-menu-link-hover
@color-menu-link-hover
*/
.form-control {
  margin-bottom: 15px;
}
.form-control .label-control {
  display: block;
  margin-bottom: 2px;
}
.form-control .input-control,
.form-control .textarea-control {
  display: block;
  width: 100%;
}
.form-control br {
  display: none;
}
.label-control {
  font-size: 1.6rem;
}
.input-control {
  border: 1px solid var(--color-base);
  box-shadow: 0 0 3px transparent;
  border-radius: 0.3em;
  padding: 10px;
  transition: 0.2s;
  height: 35px;
}
.input-control:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 3px var(--color-primary);
}
.textarea-control {
  border: 1px solid var(--color-base);
  box-shadow: 0 0 3px transparent;
  border-radius: 0.3em;
  padding: 10px;
  transition: 0.2s;
  height: 80px;
}
.textarea-control:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 3px var(--color-primary);
}
#trilha {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
  margin-top: 10px;
  padding: 0;
  list-style: none;
}
#trilha li {
  float: left;
  color: var(--color-white);
  font-size: 1.4rem;
  position: relative;
  list-style: none;
  transition: 0.2s;
}
#trilha li + li {
  margin-left: 30px;
}
#trilha li + li:before {
  content: ">";
  color: var(--color-white);
  position: absolute;
  left: -20px;
}
#trilha a {
  color: var(--color-white);
  border-bottom: 1px solid transparent;
  transition: 0.2s;
}
#trilha a:hover {
  border-color: var(--color-white);
}
#trilha .current,
#trilha strong {
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
  font-weight: bold;
}
.menu-categories {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-categories.vertical {
  display: inline;
  float: left;
  width: 22.91666667%;
  margin: 0 1.04166667%;
  border-top: 1px solid var(--color-base);
}
.menu-categories.vertical + #produtos {
  display: inline;
  float: left;
  width: 72.91666667%;
  margin: 0 1.04166667%;
}
.menu-categories.vertical + #produtos > ul {
  display: block;
  width: 102.77777778%;
  margin: 0 -1.38888889%;
  *zoom: 1;
}
.menu-categories.vertical + #produtos > ul:before,
.menu-categories.vertical + #produtos > ul:after {
  content: "";
  display: table;
}
.menu-categories.vertical + #produtos > ul:after {
  clear: both;
}
.menu-categories.vertical + #produtos > ul li {
  display: inline;
  float: left;
  width: 30.55555556%;
  margin: 0 1.38888889%;
}
.menu-categories.vertical a {
  display: block;
  padding: 10px;
  transition: 0.2s;
  border: solid var(--color-base);
  border-width: 0 1px 1px 1px;
  background: var(--color-white);
}
.menu-categories.vertical a:hover {
  background: var(--color-primary-light1);
  color: var(--color-white);
}
.menu-categories.vertical ul > li a {
  text-indent: 5px;
}
.menu-categories.vertical ul > li a:before {
  content: "- ";
}
.menu-categories.vertical .current-cat > a {
  background: var(--color-primary-light2);
  color: var(--color-white);
}
.menu-categories.vertical .current-cat > a:hover {
  background: var(--color-primary-light1);
}
.menu-categories.horizontal {
  display: table;
  margin: 0 auto 35px;
}
.menu-categories.horizontal ul,
.menu-categories.horizontal li {
  float: left;
}
.menu-categories.horizontal a {
  padding: 6px 10px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  margin: 5px;
  border-radius: 0.2em;
  display: inline-block;
}
.menu-categories.horizontal a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}
.menu-categories.horizontal .current-cat > a {
  background: var(--color-primary);
  color: var(--color-white);
}
.menu-categories.horizontal .current-cat > a:hover {
  background: var(--color-white);
  color: var(--color-primary);
}
@media only screen and (max-width: 949px) {
  .nav-global > ul {
    visibility: hidden;
    display: none;
    transition: 0.2s;
    margin: 0;
    padding: 10px 0;
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;
    background: var(--color-primary);
    color: #fff;
    list-style: none;
  }
  .nav-global > ul .menu-item a {
    color: var(--color-white);
    padding: 25px 15px;
  }
  .nav-global > ul .menu-item a:after {
    content: none;
  }
  .nav-global > ul .menu-item.current-menu-item a {
    background: var(--color-white);
    color: var(--color-primary);
  }
}
@media only screen and (min-width: 950px)  {
  .nav-global > ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
.nav-global-mobile {
  font-size: 4rem;
}
@media only screen and (min-width: 950px)  {
  .nav-global-mobile {
    display: none;
  }
}
.nav-global-mobile.active:after {
  content: "\f00d";
}
.nav-global-mobile.active + ul {
  display: block;
  visibility: visible;
}
.nav-global-mobile:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0c9";
}
.menu-item + li {
  margin-left: 3rem;
}
.menu-item a {
  text-decoration: none;
  padding: 10px 0;
  transition: 0.2s;
  font-weight: bold;
  font-size: 1.4rem;
  display: block;
}
.menu-item a {
  color: var(--color-primary);
  position: relative;
}
.menu-item a:after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: var(--color-primary);
  left: 50%;
  width: 0;
  bottom: 0;
  transition: 0.2s;
  position: absolute;
}
.menu-item a:hover:after,
.menu-item a:focus:after {
  left: 0;
  width: 100%;
}
.menu-item.current-menu-item a:after {
  left: 0;
  width: 100%;
}
@media only screen and (max-width: 949px) {
  .menu-item + .menu-item {
    margin: 0;
  }
}
/*
basico
line-down
line-down-hover-bg
hover-button
ghost
*/
.newsletter {
  background: var(--color-primary);
  padding: 35px;
}
@media only screen and (max-width: 949px) {
  .newsletter {
    text-align: center;
  }
}
@media only screen and (min-width: 950px)  {
  .newsletter form {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .newsletter form:after,
  .newsletter form:before {
    content: none;
  }
}
.newsletter .label-control {
  color: #fff;
  font-size: 2rem;
  line-height: 1.2;
}
@media only screen and (min-width: 950px)  {
  .newsletter .label-control {
    width: 22%;
    display: block;
    float: left;
  }
}
.newsletter .input-control {
  height: 100%;
  display: block;
}
@media only screen and (max-width: 949px) {
  .newsletter .input-control {
    width: 100%;
    margin: 10px 0;
  }
}
@media only screen and (min-width: 950px)  {
  .newsletter .input-control {
    width: 50%;
    float: left;
  }
}
.newsletter .button {
  background: var(--color-white);
  height: 100%;
  color: var(--color-primary);
  font-weight: bold;
}
@media only screen and (max-width: 949px) {
  .newsletter .button {
    width: 100%;
  }
}
@media only screen and (max-width: 949px) {
  .button {
    padding: 1.5rem 1rem;
  }
}
.module-section {
  padding: 35px 0;
}
.module-section.basico > .container {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  *zoom: 1;
}
.module-section.basico > .container > p,
.module-section.basico > .container > h1,
.module-section.basico > .container > h2,
.module-section.basico > .container > h3,
.module-section.basico > .container > h4,
.module-section.basico > .container > h5,
.module-section.basico > .container > h6 {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
}
@media only screen and (max-width: 949px) {
  .module-section.basico > .container {
    padding: 0 15px;
  }
}
.module-section.basico > .container:before,
.module-section.basico > .container:after {
  content: "";
  display: table;
}
.module-section.basico > .container:after {
  clear: both;
}
.module-section.basico > .container > section {
  display: table;
  width: 100%;
}
.module-section.basico .module-block img,
.module-section.basico .module-block h3 {
  margin: 10px 0;
}
.module-section.zigzag > .container {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  *zoom: 1;
}
.module-section.zigzag > .container > p,
.module-section.zigzag > .container > h1,
.module-section.zigzag > .container > h2,
.module-section.zigzag > .container > h3,
.module-section.zigzag > .container > h4,
.module-section.zigzag > .container > h5,
.module-section.zigzag > .container > h6 {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
}
@media only screen and (max-width: 949px) {
  .module-section.zigzag > .container {
    padding: 0 15px;
  }
}
.module-section.zigzag > .container:before,
.module-section.zigzag > .container:after {
  content: "";
  display: table;
}
.module-section.zigzag > .container:after {
  clear: both;
}
.module-section.zigzag > .container > section {
  display: table;
  width: 100%;
}
.module-list {
  width: 100%;
  display: table;
}
.module-block:not(:only-child) {
  margin-bottom: 35px !important;
}
@media only screen and (max-width: 610px) {
  .module-block:not(:only-child) {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 611px) and (max-width: 949px) {
  .module-block:not(:only-child) {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .module-block:not(:only-child) {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 611px) and (max-width: 949px) {
  .tablet-one .module-block {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
  .tablet-two .module-block {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
  .tablet-three .module-block {
    display: inline;
    float: left;
    width: 31.25%;
    margin: 0 1.04166667%;
  }
  .tablet-four .module-block {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
  }
  .tablet-five .module-block {
    display: inline;
    float: left;
    width: 17.91666667%;
    margin: 0 1.04166667%;
  }
  .tablet-six .module-block {
    display: inline;
    float: left;
    width: 14.58333333%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (max-width: 610px) {
  .phone-one .module-block {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
  .phone-two .module-block {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
  .phone-three .module-block {
    display: inline;
    float: left;
    width: 31.25%;
    margin: 0 1.04166667%;
  }
  .phone-four .module-block {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
  }
  .phone-five .module-block {
    display: inline;
    float: left;
    width: 17.91666667%;
    margin: 0 1.04166667%;
  }
  .phone-six .module-block {
    display: inline;
    float: left;
    width: 14.58333333%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .one .module-block {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
  .two .module-block {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
  .three .module-block {
    display: inline;
    float: left;
    width: 31.25%;
    margin: 0 1.04166667%;
  }
  .four .module-block {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
  }
  .five .module-block {
    display: inline;
    float: left;
    width: 17.91666667%;
    margin: 0 1.04166667%;
  }
  .six .module-block {
    display: inline;
    float: left;
    width: 14.58333333%;
    margin: 0 1.04166667%;
  }
  .module-block[data-columns] {
    width: 100%;
    column-gap: 15px;
  }
  .module-block[data-columns="2 colunas"] {
    column-count: 2;
  }
  .module-block[data-columns="3 colunas"] {
    column-count: 3;
  }
  .module-block[data-columns="4 colunas"] {
    column-count: 4;
  }
}
.text-center .module-block {
  text-align: center;
}
[class^='loop'] {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media only screen and (min-width: 950px)  {
  .one [class^='loop'] .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
  .two [class^='loop'] .item {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
  .three [class^='loop'] .item {
    display: inline;
    float: left;
    width: 31.25%;
    margin: 0 1.04166667%;
  }
  .four [class^='loop'] .item {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
  }
  .five [class^='loop'] .item {
    display: inline;
    float: left;
    width: 17.91666667%;
    margin: 0 1.04166667%;
  }
  .six [class^='loop'] .item {
    display: inline;
    float: left;
    width: 14.58333333%;
    margin: 0 1.04166667%;
  }
}
.module-header {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
  margin-bottom: 35px;
  text-align: center;
}
.amarelo {
  background: yellow;
}
.slider {
  overflow: hidden;
}
.slider.box {
  margin-top: 20px;
}
.slider > ul {
  height: 300px;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.slide {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 1s;
  opacity: 0;
  visibility: hidden;
}
.slide.active {
  opacity: 1;
  visibility: visible;
}
.slide img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: initial!important;
}
.slide .wrap {
  height: 100%;
}
.slide .wrap:before,
.slide .wrap:after {
  display: none;
}
.slide .banner-title {
  font-size: 4rem;
}
.slide .banner-text {
  line-height: 1.4;
  font-size: 2.4rem;
}
.slide .button {
  padding: 1em 2em;
}
.bullets-wrap {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  *zoom: 1;
}
.bullets-wrap > p,
.bullets-wrap > h1,
.bullets-wrap > h2,
.bullets-wrap > h3,
.bullets-wrap > h4,
.bullets-wrap > h5,
.bullets-wrap > h6 {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
}
@media only screen and (max-width: 949px) {
  .bullets-wrap {
    padding: 0 15px;
  }
}
.bullets-wrap:before,
.bullets-wrap:after {
  content: "";
  display: table;
}
.bullets-wrap:after {
  clear: both;
}
.bullets-wrap > section {
  display: table;
  width: 100%;
}
.bullets-wrap .bullets {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}
.bullets-wrap .bullet {
  text-indent: -9999px;
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 1em;
  margin: 0 10px;
  transition: 0.2s;
  background: var(--color-base-light2);
}
.bullets-wrap .bullet.active {
  background: var(--color-primary);
}
:root {
  --bg-header-site: var(--color-white);
  --bg-header-page: var(--color-primary);
}
.header-site {
  background: var(--bg-header-site);
  border-bottom: 1px solid var(--color-primary);
  position: relative;
  z-index: 6000;
}
.header-site .wrap {
  display: flex;
  height: 100%;
  padding: 20px 0;
}
.header-site .wrap:after,
.header-site .wrap:before {
  display: none;
}
.header-site .wrap {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 949px) {
  .brand-link {
    width: 80px;
  }
}
.header-page {
  background: var(--bg-header-page);
  padding: 35px 0;
  margin-bottom: 35px;
}
.page .header-page {
  margin-bottom: 0;
}
.header-page h1 {
  color: var(--color-white);
}
.menu,
.menu-user {
  display: flex;
}
.menu a,
.menu-user a {
  display: block;
}
.preline p {
  margin-bottom: 0!important;
}
.footer-site {
  background: var(--color-primary);
}
.footer-site .copyright {
  color: #fff;
  font: 100 1.4rem/1.5 var(--font-base);
}
.footer-site .wrap > .top,
.footer-site .wrap > .bottom {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  margin: 0 15px;
}
@media only screen and (max-width: 949px) {
  .footer-site .wrap > .top,
  .footer-site .wrap > .bottom {
    flex-direction: column;
  }
}
@media only screen and (min-width: 950px)  {
  .footer-site .wrap > .top,
  .footer-site .wrap > .bottom {
    flex-direction: row;
  }
}
.footer-site .wrap > .bottom {
  align-items: center;
}
.footer-site .wrap > div + div {
  border-top: 2px solid #00000024;
}
.footer-site > .wrap {
  padding: 15px 0;
}
.redes-sociais {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.redes-sociais > .item i {
  font-size: 1.6rem;
}
.footer-site .redes-sociais > .item i {
  color: #fff;
}
.main .redes-sociais > .item i {
  color: var(--color-primary);
}
.redes-sociais > .item + .item {
  margin-left: 10px;
}
.enderecos {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-site .enderecos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.enderecos > .item {
  font: 100 1.4rem var(--font-base);
}
@media only screen and (max-width: 949px) {
  .enderecos > .item {
    display: block;
    width: 100%;
  }
}
.footer-site .enderecos > .item {
  color: var(--color-white);
}
@media only screen and (max-width: 949px) {
  .footer-site .enderecos > .item + .item {
    margin: 35px 0;
  }
}
@media only screen and (min-width: 950px)  {
  .footer-site .enderecos > .item {
    flex: 1;
  }
  .footer-site .enderecos > .item + .item {
    margin-left: 35px;
  }
}
.footer-site .enderecos > .item span {
  display: block;
}
.main .enderecos > .item {
  margin-bottom: 15px;
}
.main .enderecos > .item .telefone {
  display: block;
}
.enderecos > .item i {
  color: inherit;
  margin-right: 5px;
}
.enderecos span {
  color: inherit;
  line-height: 1.5;
  font-weight: normal;
}
.enderecos span b {
  color: inherit;
}
@media only screen and (max-width: 949px) {
  .logo-dev img {
    margin: 25px 0;
  }
}
[class^='loop'] {
  margin: 0;
  padding: 0;
  list-style: none;
}
[class^='loop'] > .item {
  list-style: none;
  margin-bottom: 35px !important;
}
@media only screen and (max-width: 610px) {
  [class^='loop'] > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 611px) and (max-width: 949px) {
  [class^='loop'] > .item {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  [class^='loop'] > .item {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
  }
}
.loop-post > .item {
  list-style: none;
  margin-bottom: 35px !important;
}
@media only screen and (max-width: 610px) {
  .loop-post > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 611px) and (max-width: 949px) {
  .loop-post > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .loop-post > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
.loop-blog-ultimas > .item {
  list-style: none;
  margin-bottom: 35px !important;
}
@media only screen and (max-width: 610px) {
  .loop-blog-ultimas > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 611px) and (max-width: 949px) {
  .loop-blog-ultimas > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .loop-blog-ultimas > .item {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
.loop-blog-ultimas img {
  border: 2px solid var(--color-primary);
  margin-bottom: 5px;
}
@media only screen and (max-width: 949px) {
  .loop-blog-ultimas img {
    display: block;
    width: 100%;
  }
}
.loop-blog-ultimas a {
  color: var(--color-primary);
  font: 1.8rem/1.2 var(--font-base);
}
.archive .type-produtos {
  text-align: center;
  padding-bottom: 15px;
  background: #ededed;
  display: block;
}
.home .type-produtos {
  text-align: center;
  padding-bottom: 15px;
  background: #ededed;
  display: block;
}
.type-produtos > .title {
  font-family: var(--font-stack);
  font-size: 1.6rem;
  color: var(--color-primary);
  line-height: 1.2;
  height: 35px;
  margin-top: 10px;
}
.type-produtos .price {
  font-family: var(--font-stack);
  font-size: 3rem;
  color: var(--color-primary);
  line-height: 1;
  margin-top: 15px;
}
.type-post .single {
  display: grid;
}
@media only screen and (max-width: 949px) {
  .type-post .single {
    grid-template-areas: "title" "image" "resume" "button";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
  }
}
@media only screen and (min-width: 950px)  {
  .type-post .single {
    grid-template-areas: "image title" "image resume" "image button";
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 55px 100px auto;
    grid-gap: 20px;
  }
}
.blog .type-post {
  background: #F5F7F8;
  padding: 20px;
  display: grid;
}
@media only screen and (max-width: 949px) {
  .blog .type-post {
    grid-template-areas: "title" "image" "resume" "button";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
  }
}
@media only screen and (min-width: 950px)  {
  .blog .type-post {
    grid-template-areas: "image title" "image resume" "image button";
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 55px 100px auto;
    grid-gap: 20px;
  }
}
.blog .type-post > .button {
  width: fit-content;
}
.blog .type-post > .title {
  font-family: var(--font-stack);
  font-size: 3rem;
  color: var(--color-primary);
}
.home .type-post {
  background: #F5F7F8;
  padding: 20px;
}
.home .type-post > .title {
  margin-top: 10px;
  font-size: 2rem;
  line-height: 1.2;
}
.home .type-post > .resume {
  font-size: 2rem;
  line-height: 1.2;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.home .type-post img {
  display: block;
  margin: 0 auto;
}
.home .type-post > .button {
  display: block;
}
.home .type-post > .title {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.type-post > .title {
  grid-area: title;
}
.type-post > .image {
  grid-area: image;
}
.type-post > .resume {
  grid-area: resume;
  margin-bottom: 10px;
}
.type-post > .button {
  grid-area: button;
}
.archive .type-servicos {
  text-align: center;
}
.archive .type-servicos > .title {
  margin-top: 10px;
  font-size: 2rem;
  line-height: 1.2;
}
.archive .type-servicos > .resume {
  font-size: 2rem;
  line-height: 1.2;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.archive .type-servicos img {
  display: block;
  margin: 0 auto;
}
.home .type-servicos {
  text-align: center;
}
.home .type-servicos > .title {
  margin-top: 10px;
  font-size: 2rem;
  line-height: 1.2;
}
.home .type-servicos > .resume {
  font-size: 2rem;
  line-height: 1.2;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.home .type-servicos img {
  display: block;
  margin: 0 auto;
}
.type-servicos > .title {
  grid-area: title;
}
.type-servicos > .image {
  grid-area: image;
}
.type-servicos > .resume {
  grid-area: resume;
}
.type-servicos > .button {
  grid-area: button;
}
.main {
  min-height: 650px;
  padding-bottom: 60px;
  *zoom: 1;
}
.main:before,
.main:after {
  content: "";
  display: table;
}
.main:after {
  clear: both;
}
.wrap > section {
  display: table;
  width: 100%;
}
.banner-full-wrap {
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
}
@media only screen and (min-width: 950px)  {
  .produto-gallery {
    display: inline;
    float: left;
    width: 39.58333333%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .produto-details {
    display: inline;
    float: left;
    width: 56.25%;
    margin: 0 1.04166667%;
  }
}
.bg-section-triangulo-baixo {
  position: relative;
  margin-bottom: 90px;
  padding-bottom: 0;
}
.bg-section-triangulo-baixo:before,
.bg-section-triangulo-baixo:after {
  content: "";
  display: block;
  background: inherit;
  width: 50.5%;
  height: 100px;
  position: absolute;
  bottom: -44px;
  z-index: -1;
}
.bg-section-triangulo-baixo:before {
  transform: rotate(5deg);
  left: 0;
}
.bg-section-triangulo-baixo:after {
  transform: rotate(-5deg);
  right: 0;
}
.bg-section-diagonal-esquerda {
  position: relative;
  margin: 50px 0;
  padding-top: 20px;
  padding-bottom: 0;
}
.bg-section-diagonal-esquerda:before,
.bg-section-diagonal-esquerda:after {
  content: "";
  display: block;
  background: inherit;
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: -1;
}
.bg-section-diagonal-esquerda:after {
  bottom: -44px;
  left: 0;
  transform: rotate(2deg);
}
.bg-section-diagonal-esquerda:before {
  top: -44px;
  left: 0;
  transform: rotate(2deg);
}
@media only screen and (max-width: 949px) {
  .half {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .half {
    display: inline;
    float: left;
    width: 47.91666667%;
    margin: 0 1.04166667%;
  }
}
.full .half {
  width: 50%;
  margin: 0;
}
.post-edit-link {
  font-size: 1.4rem;
  font-family: var(--font-base);
  text-decoration: underline;
  margin-top: 10px;
  display: block;
}
.grid-wrap,
.grid-one-third-col,
.grid-two-col,
.grid-two-col-push-2 {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
}
.grid-wrap > .description,
.grid-one-third-col > .description,
.grid-two-col > .description,
.grid-two-col-push-2 > .description {
  font: 100 1.4rem var(--font-base);
  display: inline;
  float: left;
  width: 97.91666667%;
  margin: 0 1.04166667%;
  padding: 35px 0;
}
@media only screen and (max-width: 949px) {
  .grid-one-third-col > div {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
  }
}
@media only screen and (min-width: 950px)  {
  .grid-one-third-col > div {
    display: inline;
    float: left;
    width: 64.58333333%;
    margin: 0 1.04166667%;
  }
  .grid-one-third-col > div + div {
    display: inline;
    float: left;
    width: 22.91666667%;
    margin: 0 1.04166667%;
    margin-left: 9.375%;
  }
}
.grid-two-col > div {
  display: inline;
  float: left;
  width: 47.91666667%;
  margin: 0 1.04166667%;
}
@media only screen and (max-width: 949px) {
  .grid-two-col-push-2 > div {
    display: inline;
    float: left;
    width: 97.91666667%;
    margin: 0 1.04166667%;
    margin-bottom: 35px;
    padding: 0 15px;
  }
}
@media only screen and (min-width: 950px)  {
  .grid-two-col-push-2 > div {
    display: inline;
    float: left;
    width: 31.25%;
    margin: 0 1.04166667%;
    margin-right: 17.70833333%;
  }
}
.entry-image {
  border: 2px solid var(--color-primary);
  margin-bottom: 30px;
}
.entry-title {
  color: var(--color-primary);
  font: 100 3.2rem var(--font-base);
  margin-bottom: 5px;
}
.entry-meta {
  font: 100 italic 1.2rem var(--font-base);
  display: block;
  margin-bottom: 35px;
}
.entry-content ul,
.entry-content ol {
  margin: 0 0 20px 0;
}
.entry-content ul li,
.entry-content ol li {
  margin-bottom: 5px;
}
.thumblist {
  margin-top: 10px;
}
.thumblist ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.thumblist ul li {
  width: 19%;
}
.thumblist ul li + li {
  margin-left: 2%;
}
.thumblist ul a {
  display: block;
  border: 2px solid transparent;
  transition: 0.2s;
}
.thumblist ul a.active {
  border-color: var(--color-primary);
}
blockquote {
  font-family: Georgia, serif;
  font-size: 18px;
  font-style: italic;
  line-height: 1.45;
  position: relative;
  color: #383838;
  margin: 0 auto;
}
@media only screen and (max-width: 949px) {
  blockquote {
    margin: 0.25em 0;
    padding: 0.35em 40px;
  }
}
@media only screen and (min-width: 950px)  {
  blockquote {
    width: 500px;
    margin: 0.25em 0;
    padding: 0.35em 40px;
  }
}
blockquote:before {
  display: block;
  padding-left: 10px;
  content: "\201C";
  font-size: 80px;
  position: absolute;
  left: -20px;
  top: -20px;
  color: #7a7a7a;
}
blockquote cite {
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
blockquote cite:before {
  content: "\2014";
}
